import { ConnectorNames } from "@bds-libs/uikit";
import { ethers } from "ethers";
import { initializeConnector } from "@web3-react/core";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { MetaMask } from "@web3-react/metamask";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";

import getNodeUrl from "./getRpcUrl";

export const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
const POLLING_INTERVAL = 12000;
const rpcUrl = getNodeUrl();

export const getLibrary = (provider): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

export const [walletConnectV2, walletConnectV2Hooks] =
  initializeConnector<WalletConnectV2>(
    (actions) =>
      new WalletConnectV2({
        actions,
        options: {
          projectId: process.env.REACT_APP_WALLET_CONNECTOR_ID,
          chains: [chainId],
          showQrModal: true,
        },
      })
  );
export const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions })
);
export const [coinbaseWallet, coinbaseWalletHooks] =
  initializeConnector<CoinbaseWallet>(
    (actions) =>
      new CoinbaseWallet({
        actions,
        options: {
          url: rpcUrl,
          appName: "Inuko",
        },
      })
  );

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: metaMask,
  [ConnectorNames.Coinbase]: coinbaseWallet,
  [ConnectorNames.WalletConnect]: walletConnectV2,
};
