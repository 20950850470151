import React from "react";
import { ModalProvider } from "@bds-libs/uikit";
import { Web3ReactHooks, Web3ReactProvider } from "@web3-react/core";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { QueryClient, QueryClientProvider } from "react-query";
import { MetaMask } from "@web3-react/metamask";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";

import {
  coinbaseWallet,
  coinbaseWalletHooks,
  metaMask,
  metaMaskHooks,
  walletConnectV2,
  walletConnectV2Hooks,
} from "utils/web3React";
import { ThemeContextProvider } from "contexts/ThemeContext";
import { HdProviderContextProvider } from "contexts/HdProviderContext";
import { LanguageProvider } from "contexts/Localization";
import { RefreshContextProvider } from "contexts/RefreshContext";
import { ToastsProvider } from "contexts/ToastsContext";
import store from "state";
import { BnbContextProvider } from "contexts/BnbContext";

const connectors: [
  MetaMask | WalletConnectV2 | CoinbaseWallet,
  Web3ReactHooks
][] = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
];

const Providers: React.FC = ({ children }) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 1,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Web3ReactProvider connectors={connectors}>
        <Provider store={store}>
          <ToastsProvider>
            <HelmetProvider>
              <ThemeContextProvider>
                <HdProviderContextProvider>
                  <BnbContextProvider>
                    <LanguageProvider>
                      <RefreshContextProvider>
                        <ModalProvider>{children}</ModalProvider>
                      </RefreshContextProvider>
                    </LanguageProvider>
                  </BnbContextProvider>
                </HdProviderContextProvider>
              </ThemeContextProvider>
            </HelmetProvider>
          </ToastsProvider>
        </Provider>
      </Web3ReactProvider>
    </QueryClientProvider>
  );
};

export default Providers;
