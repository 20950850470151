import { useCallback, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { ConnectorNames } from "@bds-libs/uikit";
import { chainId, connectorsByName } from "utils/web3React";
import useToast from "hooks/useToast";
import { profileClear } from "state/profile";
import { useAppDispatch } from "state";

declare global {
  interface Window {
    kardiachain: any;
    kaiAccount: any;
  }
}

const useAuth = () => {
  const dispatch = useAppDispatch();
  const { connector, account, isActivating, isActive } = useWeb3React();
  // console.log("account", account, isActivating, isActive);
  const { toastError } = useToast();

  const tryConnect = useCallback(
    async (connectorID) => {
      try {
        const connector = connectorsByName[connectorID];
        if (connector) {
          if (connector.connectEagerly) {
            await connector.connectEagerly();
          } else {
            await connector.activate();
          }
        }
      } catch (error) {
        console.log(`web3-react eager connection error: ${error}`);
      }
    },
    [connectorsByName]
  );

  useEffect(() => {
    const connectorID = localStorage.getItem("connectorID");
    if (connectorID) {
      tryConnect(connectorID);
    }
  }, [tryConnect]);

  const login = useCallback(async (connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID];
    if (connector) {
      try {
        const res = await connector.activate(chainId);
        console.log("done", connectorID, res);
        localStorage.setItem("connectorID", connectorID);
      } catch (error) {
        console.log("error", error);
        console.log(error);
      }
    } else {
      toastError("Can't find connector", "The connector config is wrong");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(() => {
    dispatch(profileClear());
    if (connector?.deactivate) {
      void connector.deactivate();
    } else {
      void connector.resetState();
    }
    localStorage.removeItem("connectorID");
  }, [connector, dispatch]);

  return { login, logout };
};

export default useAuth;
