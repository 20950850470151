import { tokens } from "./../config/constants/tokens";
import { Contract } from "@ethersproject/contracts";
import { AddressZero } from "@ethersproject/constants";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import { BigNumber } from "@ethersproject/bignumber";
import RawBigNumber from "bignumber.js";
import { CurrencyAmount } from "@pancakeswap/sdk";
import { abi as IUniswapV2Router02ABI } from "@uniswap/v2-periphery/build/IUniswapV2Router02.json";
import {
  ChainId,
  JSBI,
  Percent,
  Token,
  Currency,
  ETHER,
} from "@pancakeswap/sdk";
import { ROUTER_ADDRESS } from "../config/constants";
import { BASE_BSC_SCAN_URLS } from "../config";
import { TokenAddressMap } from "../state/lists/hooks";
import { ethers } from "ethers";
import { fetchSwapEvents } from "hooks/graph";
import { getAddress } from "./addressHelpers";

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function getBscScanLink(
  data: string | number,
  type: "transaction" | "token" | "address" | "block" | "countdown",
  chainId: ChainId = ChainId.MAINNET
): string {
  switch (type) {
    case "transaction": {
      return `${BASE_BSC_SCAN_URLS[chainId]}/tx/${data}`;
    }
    case "token": {
      return `${BASE_BSC_SCAN_URLS[chainId]}/token/${data}`;
    }
    case "block": {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/${data}`;
    }
    case "countdown": {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/countdown/${data}`;
    }
    default: {
      return `${BASE_BSC_SCAN_URLS[chainId]}/address/${data}`;
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value
    .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
    .div(BigNumber.from(10000));
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000));
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}

// account is optional
export function getRouterContract(
  _: number,
  library: Web3Provider,
  account?: string
): Contract {
  return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI, library, account);
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function isTokenOnList(
  defaultTokens: TokenAddressMap,
  currency?: Currency
): boolean {
  if (currency === ETHER) return true;
  return Boolean(
    currency instanceof Token &&
      defaultTokens[currency.chainId]?.[currency.address]
  );
}

export function calculateSlippageAmount(
  value: CurrencyAmount,
  slippage: number
): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`);
  }
  return [
    JSBI.divide(
      JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)),
      JSBI.BigInt(10000)
    ),
    JSBI.divide(
      JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)),
      JSBI.BigInt(10000)
    ),
  ];
}

export function hexParams(abi: string[], func: string, params: any[]) {
  const iface = new ethers.utils.Interface(abi);
  let tmp: any = iface.encodeFunctionData(func, params);
  tmp = tmp.slice(10);
  return "0x" + tmp;
}

export const getLatestPriceByPair = async (
  subgraph: string,
  pair: string,
  address: string,
  ignoreDecimal = false
) => {
  if (
    address?.toLowerCase() === getAddress(tokens.usdt.address)?.toLowerCase()
  ) {
    return 1;
  }
  if (
    address?.toLowerCase() === getAddress(tokens.busd.address)?.toLowerCase()
  ) {
    return 1;
  }
  const events = await fetchSwapEvents(subgraph, pair, 1);
  const data = events.data.swaps?.[0];
  if (!data) {
    return 0;
  }
  // const isToken0 = data.pair.token0?.id === address;
  const isToken0 = subgraph?.includes("dash") || subgraph?.includes("solcash");
  const amount = isToken0
    ? +data.amount0Out || +data.amount0In
    : +data.amount1Out || +data.amount1In;
  const usdAmount = +data.amountUSD;

  return (
    (usdAmount * 1.0) /
    (subgraph?.includes("liq") ||
    subgraph?.includes("dash") ||
    subgraph?.includes("solcash") ||
    ignoreDecimal
      ? amount
      : new RawBigNumber(amount).div(new RawBigNumber(10).pow(18)).toNumber())
  );
};

export const getPrices = async (
  subgraph: string,
  pair: string,
  address: string,
  ignoreDecimal = false
) => {
  if (
    address?.toLowerCase() === getAddress(tokens.usdt.address)?.toLowerCase()
  ) {
    return [
      {
        price: 1,
        timestamp: new Date().getTime() / 1000,
      },
    ];
  }
  if (
    address?.toLowerCase() === getAddress(tokens.busd.address)?.toLowerCase()
  ) {
    return [
      {
        price: 1,
        timestamp: new Date().getTime() / 1000,
      },
    ];
  }
  const events = await fetchSwapEvents(subgraph, pair);
  return events.data.swaps?.map((o) => {
    // const isToken0 = o.pair.token0?.id === address;
    const isToken0 =
      subgraph?.includes("dash") || subgraph?.includes("solcash");
    const amount = isToken0
      ? +o.amount0Out || +o.amount0In
      : +o.amount1Out || +o.amount1In;
    const usdAmount = +o.amountUSD;

    return {
      ...o,
      price:
        (usdAmount * 1.0) /
        (subgraph?.includes("liq") ||
        subgraph?.includes("dash") ||
        subgraph?.includes("solcash") ||
        ignoreDecimal
          ? amount
          : new RawBigNumber(amount)
              .div(new RawBigNumber(10).pow(18))
              .toNumber()),
    };
  });
};
