import { MenuEntry } from "@bds-libs/uikit";

const config = (t: (r: string) => string) => {
  const result: MenuEntry[] = [
    {
      label: t("Dashboard"),
      icon: "DashboardIcon",
      showOnMobile: true,
      href: "/",
    },
    {
      label: t("Market (beta)"),
      icon: "BondIcon",
      showOnMobile: true,
      href: "/markets",
    },

    /*{
      label: t("V2 Bond"),
      icon: "BondIcon",
      showOnMobile: true,
      href: "/bond",
    },*/
    {
      label: t("Exchange"),
      icon: "NewTradeIcon",
      showOnMobile: true,
      href: "/swap",
    },
    {
      label: t("Liquidity"),
      icon: "NewLiquidityIcon",
      showOnMobile: true,
      href: "/liquidity",
    },
    {
      label: t("Staking"),
      icon: "StakeIcon",
      showOnMobile: true,
      href: "/staking",
    },
    {
      label: t("Shares"),
      icon: "SharesIcon",
      showOnMobile: true,
      href: "/shares",
    },
    {
      label: t("Documentation"),
      icon: "DocumentIcon",
      showOnMobile: true,
      href: "https://docs.inuko.finance",
    },
  ];

  return result;
};

export default config;
